import { AxiosSingular } from '@deleteagency/axios-singular';
import axios from 'axios';
import { EnhancedImage } from 'components/enhanced-image';
import PropListCarouselComponent from 'components/props-panel/js/prop-panel-carousel.component';
import analyticsService from 'general/js/analytics-service';
import api from 'general/js/api';
import ArrayHelper from 'general/js/array-helper';
import { transformRequestOptions } from 'general/js/url-helper';
import React from 'preact/compat';
import propTypes from 'prop-types';
import OnVisible from 'react-on-visible';

const RECENTLY_VIEWED_PROPERTIES_KEY = 'winkworth_recently_viewed_properties_key';
class RecentlyViewedProperties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: [],
            isFetching: false,
        };
        this.axiosSingular = new AxiosSingular(api, axios.CancelToken, axios.isCancel);
        this.carousel = null;
        this.carouselContainer = React.createRef();
        this.isActivated = false;
    }

    onVisible = async (visible) => {
        if (this.isActivated) return;

        if (!this.isKeyExists()) return;

        if (visible) {
            const propertyIds = this.getViewedPropertyIds();

            if (propertyIds.length > 0) {
                await this.fetchProperties(propertyIds);
            }

            if (this.carouselContainer.current) {
                this.carousel = new PropListCarouselComponent(this.carouselContainer.current);

                this.carousel.onInit();

                this.isActivated = true;
            }
        }
    };

    isKeyExists = () => localStorage.getItem(RECENTLY_VIEWED_PROPERTIES_KEY) !== null;

    getViewedPropertyIds = () => JSON.parse(localStorage.getItem(RECENTLY_VIEWED_PROPERTIES_KEY));

    async fetchProperties(propertyIds) {
        this.setState({ isFetching: true });

        return this.axiosSingular
            .get(this.props.endpointUrl, {
                params: { propertyIds },
                paramsSerializer: (params) => transformRequestOptions(params),
            })
            .then(
                (response) => {
                    this.setState({
                        properties: response.data.properties,
                        isFetching: false,
                    });
                },
                (error) => {
                    if (!axios.isCancel(error)) {
                        // eslint-disable-next-line no-console
                        console.error('Actual error!', error);
                    }
                }
            );
    }

    onClick = ($property) => (e) => {
        analyticsService.sendEvent(document.title, 'Recently Viewed Properties', $property);
    };

    render() {
        const { properties } = this.state;
        const { title } = this.props;

        return (
            <OnVisible onChange={this.onVisible}>
                {!ArrayHelper.isEmpty(properties) && properties.length >= 3 && (
                    <div className="page-section props-panel props-panel--odd">
                        <div className="page-section__inner props-panel__inner">
                            <header className="section-header">
                                <div className="section-header__row">
                                    <h2
                                        className="section-header__title section-header__title--center"
                                        dangerouslySetInnerHTML={{
                                            __html: title,
                                        }}
                                    ></h2>
                                </div>
                            </header>
                            <ul
                                className="props-panel__props flick-car flick-car--mobile-only"
                                ref={this.carouselContainer}
                            >
                                {properties.map((property) => (
                                    <li className="props-panel__prop">
                                        <article key={property.propertyId}>
                                            <div className="prop-preview">
                                                <div className="props-panel__pic prop-preview__pic-wrap">
                                                    <a
                                                        href={property.urlToDetails}
                                                        className="prop-preview__pic-link"
                                                        onClick={this.onClick(property.title)}
                                                        aria-labelledby={property.propertyId}
                                                    >
                                                        <EnhancedImage
                                                            image={property.image.url}
                                                            alt={property.title}
                                                        />
                                                    </a>
                                                </div>
                                                <h3
                                                    className="prop-preview__title"
                                                    id={property.propertyId}
                                                >
                                                    <a
                                                        href={property.urlToDetails}
                                                        onClick={this.onClick(property.title)}
                                                    >
                                                        {property.title}
                                                    </a>
                                                </h3>
                                                <p
                                                    className="prop-preview__price"
                                                    dangerouslySetInnerHTML={{
                                                        __html: property.description,
                                                    }}
                                                ></p>
                                            </div>
                                            <script
                                                type="application/ld+json"
                                                dangerouslySetInnerHTML={{
                                                    __html: property.imageObjectJson,
                                                }}
                                            ></script>
                                        </article>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </OnVisible>
        );
    }
}

RecentlyViewedProperties.propTypes = {
    endpointUrl: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
};

export default RecentlyViewedProperties;
