import React from 'preact/compat';
import { LazyImage } from 'components/lazy-image';

const sizes = [320, 360, 640, 720, 960, 1280, 1440];

const EnhancedImage = ({ image, ...rest }) => {
    if (!image) return null;

    const srcString = [];

    const getDelimeter = () => {
        if (image.includes('?')) return '&';

        return '?';
    };

    sizes.forEach((size) => {
        srcString.push(`${image}${getDelimeter()}width=${size} ${size}w`);
    });

    return <LazyImage image={srcString.join(', ')} {...rest} />;
};

export default EnhancedImage;
