import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'preact';
import React from 'preact/compat';
import RecentlyViewedProperties from './recently-viewed-properties';

export default class RecentlyViewedPropertiesComponent extends DcBaseComponent {
    static getNamespace() {
        return 'recently-viewed-properties';
    }

    onInit() {
        const { endpointUrl } = this.options;
        render(
            <RecentlyViewedProperties
                endpointUrl={endpointUrl}
                title={this.options.title || 'Recently Viewed Properties'}
            />,
            this.element
        );
    }
}
